h1,h2 {
    color: white; padding-left:10px; padding-right:10px;
}
h1 {
    font-size: 32px;
}
h2 {
    font-size: 24px;
}
li {
    margin-bottom: 5px;
    font-size: unset;
}
