
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table {
  width: 100%
}
.ck-rounded-corners .ck.ck-balloon-panel, .ck.ck-balloon-panel.ck-rounded-corners, .ck{z-index:9999 !important}
.ck-editor__editable {
  min-height: 100px;
}
.ck-content p {
  margin: 0!important;
  padding: 0!important;
}
.ck-content {
  font-size: 13px!important;
  padding: 10px!important;
  font-family: Verdana;
}

.MuiAutocomplete-popper {
  z-index: 999999999!important;
}
